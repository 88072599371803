<template>
 <div>
   <div slot="header" class="modal-header popup-header">
      <span class="modal-title-popup"><i class="fa fa-credit-card" aria-hidden="true"></i>Create Invoice</span>
      <i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
   </div>
   <div class="card card-custom" v-loading="loading">
      <div class="card-body p-0">
         <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
            <div class="row justify-content-center ">
               <div class="col-xl-12">
                  <el-form :model="form"  ref="form" label-position="left" label-width="150px" :disabled="form.submiting">
                     <el-form-item label="Invoice Date" prop="invoice_date">
                        <el-form-item prop="invoice_date" style="margin-bottom:0px;">
                        <el-date-picker  v-model="form.invoice_date" placeholder="DD-MM-YYYY" type="date" format="dd MMM yyyy" style="width:100%;" > </el-date-picker>
                        <span class="form-err" v-if="form.errors.has('invoice_date')" >{{form.errors.get('invoice_date')}}</span>
                        </el-form-item>
                     </el-form-item>
                     <el-form-item label="Due Date" prop="status">
                        <el-form-item prop="payment_due_date" style="margin-bottom:0px;">
                        <el-date-picker  v-model="form.payment_due_date" placeholder="DD-MM-YYYY" type="date" format="dd MMM yyyy" style="width:100%;" > </el-date-picker>
                        <span class="form-err" v-if="form.errors.has('payment_due_date')" >{{form.errors.get('payment_due_date')}}</span>
                        </el-form-item>
                     </el-form-item>
                     <el-form-item style="margin-top:25px;">
                        <el-button class="btn btn-sm btn-primary" type="primary" @click="submitForm" >{{submit_btn_text}}</el-button>
                        <el-button class="btn btn-sm btn-warning" type="warning" @click.prevent="reset()" >Reset</el-button>
                      </el-form-item>
                  </el-form>
               </div>
            </div>
         </div>
      </div>
   </div>
 </div>
</template>

<script>
import Form from '@/core/services/api.form.services'
import { globalEvents } from '@/utils/globalEvents'
export default {
  name: 'create-user',
   props: {
    order: {
      type: Object
    },
  },
  data() {
    return {
      loading:false,
      form: new Form({
        invoice_date: new Date(),
        payment_due_date: new Date(),
        order_id: null,
        total_amount: null,
        balance_amount: null,
        invoice_status: 'unpaid'
      }),
      api_url: '/invoice/generate',
      response_msg: 'Invoice has been created',
      submit_btn_text: 'Generate'
    }
  },
  components: {
  },
  methods: {
    reset() {
      this.form.reset()
      this.$refs['form'].resetFields()
    },
    closeForm() {
      if (!this.form.submiting) {
        this.$emit('close')
      }
    },
    submitForm() {
      this.loading=true;
      this.form.invoice_date = this.$formatDate(this.form.invoice_date, 'YYYY-MM-DD');
      this.form.payment_due_date = this.$formatDate(this.form.payment_due_date, 'YYYY-MM-DD');
      this.form.post(this.api_url).then((response) => {
        this.$message({
          message: this.response_msg,
          type: 'success'
        })
        globalEvents.$emit('invoiceAdded', response.data)
        this.$emit('close')
        this.loading=false
      }).catch((error) => { 
        console.log(error);
        this.loading=false
      })
    },
  },
  created() {
    if(this.order.id) {
      this.form.order_id = this.order.id
      this.form.total_amount = this.order.total
      this.form.balance_amount = this.order.total
    }
  }
}
</script>